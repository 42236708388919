.FileUpload {
    position: relative;
    width: 180px;
    height: 72px;
    border: 1px solid silver;
}

.FileUpload__zone {
    /* min-height: 4em; */
    padding: 8px 12px;
    border: 1px solid grey;

    cursor: pointer;
}

.FileUpload.FileUpload--active .FileUpload__zone {
    border: 2px dashed grey;
}