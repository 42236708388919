.FileInput {
    /* width: 100%; */
    width: 180px;
    height: 72px;
}

.FileInput__zone {
    width: 100%;
    height: 100%;
    padding: 8px 12px;
    border: 1px solid silver;
    cursor: pointer;
}

.FileInput.FileInput--active .FileInput__zone {
    border: 2px dashed silver;
}

.FileInput__label {
    max-width: 90px;
}
