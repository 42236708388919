/* bootstrap colors */

.dropdown-toggle {
  color: #337ab7;
  border: none;
  background-color: transparent;
}

.dropdown-toggle:hover {
  color: #23527c;
  background-color: #f9fafc;
}
