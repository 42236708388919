@media only screen and (min-width: 992px) {
    .menu-toggler.sidebar-toggler {
        display: none !important;
    }
}

.__PROGRESSBAR__ {
    transition: background-color 2s;
}

.__PROGRESSBAR__.-error {
    background-color: #ff0000;
}


/* --- bootstrap override --- */
.progress-bar-primary {
    /* background-color: #36c6d3; */
    background-color: #26a1ab;
}


.d-flex {
    display: flex;
}

.flex-row {
    position: relative;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
}

.flex-row .col {
    flex: 1 auto;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.flex-row .col--shrink {
    flex: 0;
}

.form-group.required .control-label:after {
    content: " *";
    color: #e02222;
}
.required {
    color: #e02222;
}

.tooltips {
    position: relative;
    display: inline-block;
    border: 1px solid #f9e491;
    visibility: hidden;
}

.tooltips .tooltiptext {
    width: 240px;
    background-color: #f9e491;
    color: #c29d0b;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -165px;
    left: 100px;
}

.tooltips .tooltiptext::after {
    content: "";
    position: absolute;
    top: 87%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #f9e491 transparent transparent;
}
.tool_tip:hover .tooltiptext {
    visibility: visible;
}
.row > div{
    margin-bottom: 0.5rem;
}

.doctor-actions-btns a{
    display: inline-block;
    padding: 5px 5px;
    margin-left: -5px;
}

.label-error{
    color: red;
}

.bonus div{
    display: inline-block;
    padding: 5px;
}

div.tip:hover {
    position: relative
}
div.tip span {
    display: none;
}
div.tip:hover span {
    border: 1px solid #82D9D4;
    padding: 5px 5px 5px 5px;
    display: block;
    z-index: 100;
    margin: 10px;
    width: 167px;
    position: absolute;
    top: -35px;
    left: 55px;
    text-decoration: none;
    background-color: white;
    /*color: #82D9D4;*/
    text-align: center;
}

.tear {
    fill: #82D9D4;
    stroke: transparent;
    stroke-width: 2PX;
    transform: rotate(0.5turn);
    transform-origin: center;
}

.bonuses-smiles-count{
    font-size: 13px!important
}

.contact_link {
    color: #34495e;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
}
.contact_link:hover, .contact_link:active, .contact_link:visited, .contact_link:focus {
    text-decoration: none;
}

.wpb-popup {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 567px;
    padding: 23px 120px 22px 34px;
    background: radial-gradient(258.82% 1291.86% at -21.58% 81.89%, #20D3E2 0%, #8DF6FF 56.24%);
    box-shadow: 0px 3.35043px 15.9145px rgba(202, 202, 202, 0.5);
    border-radius: 15px !important;
    position: absolute;
    z-index: 2;
    left: 40%;
    transform: translate(-50%, -50%);
}
.wpb-popup *, .wpb-popup :after, .wpb-popup :before {
    box-sizing: border-box;
}
@media (max-width: 768px) {
    .wpb-popup {
        padding: 23px 93px 22px 20px;
        max-width: 75%;
    }
}
.wpb-popup.wpb-popup--small {
    padding: 19px 100px 21px 78px;
}
@media (max-width: 768px) {
    .wpb-popup.wpb-popup--small {
        padding: 19px 75px 21px 79px;
    }
}
.wpb-popup.wpb-popup--big {
    width: 413px;
    padding: 226px 20px 42px;
    background: radial-gradient(258.82% 1291.86% at -21.58% 81.89%, #20D3E2 0%, #8DF6FF 56.24%);
    box-shadow: 0px 16px 49.8414px rgba(214, 214, 222, 0.4);
    border-radius: 18px;
}
@media (max-width: 768px) {
    .wpb-popup.wpb-popup--big {
        padding: 226px 10px 32px;
    }
}
.wpb-popup__ico {
    position: absolute;
}
@media (max-width: 768px) {
    .wpb-popup__ico {
        /*right: 8px;*/
    }
}
.wpb-popup--small .wpb-popup__ico {
    top: 3px;
    right: auto;
    left: 25px;
}
@media (max-width: 768px) {
    .wpb-popup--small .wpb-popup__ico {
        right: auto;
        left: 10px;
    }
}
.wpb-popup--big .wpb-popup__ico {
    top: 21px;
    left: 0;
    right: 17px;
    text-align: center;
}
.wpb-popup__ico img {
    vertical-align: top;
}
.wpb-popup__body {
    color: #fff;
    font-family: "Aktiv Grotesk Trial", Aktiv Grotesk Trial, Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.02em;
}
.wpb-popup__body strong {
    font-weight: 700;
}
.wpb-popup--small .wpb-popup__body {
    text-align: center;
}
.wpb-popup--big .wpb-popup__body {
    font-size: 22px;
    line-height: 30px;
    text-align: center;
}
.wpb-popup__close {
    position: absolute;
    z-index: 15;
    top: 21px;
    right: 21px;
    width: 14px;
    height: 14px;
}
.wpb-popup__close:before, .wpb-popup__close:after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-bottom: 2px solid #fff;
}
.wpb-popup__close:before {
    transform: rotate(-45deg);
}
.wpb-popup__close:after {
    transform: rotate(45deg);
}
.wpb-popup__btn-ok {
    margin: 15px 0 0;
    text-align: center;
}
.wpb-popup--small .wpb-popup__btn-ok {
    position: absolute;
    right: 45px;
    top: 18px;
    margin: 0;
}
@media (max-width: 768px) {
    .wpb-popup--small .wpb-popup__btn-ok {
        right: 15px;
        border-radius: 12px !important;
    }
}
.wpb-popup--big .wpb-popup__btn-ok {
    margin: 20px 0 0;
}
.wpb-popup__btn-ok button {
    display: block;
    background: #F7F7F7;
    box-shadow: inset 0px 3.35043px 10.8889px #FFFFFF;
    border-radius: 12px;
    height: 28px;
    width: 62px;
    color: #ACACAC;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 12px;
    line-height: 24px;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    border-radius: 12px !important;
    border: none;
}
.wpb-popup--big .wpb-popup__btn-ok button {
    width: 91px;
    height: 30px;
    font-size: 11px;
    line-height: 30px;
    margin: 0 auto;
}
.wpb-popup__btn-ok button:hover {
    background: #fff;
    color: #000;
}
.hover:hover {
    border-bottom: 1px solid #32c5d2;
}
.hover-top:hover {
    border-top: 1px solid #32c5d2;
}

.custom-modal {
    position: fixed;
    top: 120px;
    z-index: 2000;
    width: 400px;
    background-color: white;
    border-radius:10px;
    border: 1px solid #32c5d2;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
}

@media (max-width: 414px) {
    .custom-modal {
        width: 260px;
        top: 180px;
    }
}

.mob-title {
    text-transform: uppercase;
    font-weight: 900;
}
@media only screen and (max-width: 614px) {
    .mob-title {
        font-size: 8px;
    }
}
.precise_tip:hover {
	position: relative
}
.precise_tip .precise_tooltip {
	display: none;
}
.precise_tip:hover .precise_tooltip {
	width: 200px;
	padding: 10px;
	font-size: 12px;
	display: block;
	z-index: 100;
	position: absolute;
	top: -110px;
	left: -10px;
	background-color: rgb(43, 42, 42);
	color: #fff;
	text-align: center;
}
.prescription_tooltip_show:hover {
    position: relative;
}
.prescription_tooltip_show .prescription_tooltip {
    display: none;
}
.prescription_tooltip_show:hover .prescription_tooltip {
    min-width: 300px;
	padding: 10px;
	font-size: 12px;
    font-weight: 400;
	display: block;
	z-index: 100;
	position: absolute;
	background-color: #F7F7F7;
	color: rgb(43, 42, 42);
    border: 1px solid #edf1f7;
	text-align: center;
}
@media only screen and (max-width: 820px) {
    .prescription_tooltip_show:hover .prescription_tooltip {
        min-width: 200px;
    }
}
@media only screen and (max-width: 414px) {
    .prescription_tooltip_show:hover .prescription_tooltip {
        position: relative;
    }
}

.react-joyride__overlay {
    z-index: 0;
}
.react-joyride__spotlight {
    position: relative !important;
}
.react-joyride__tooltip {
    border-radius: 18px !important;
    color: #8DF6FF;
}
.btn_tour {
    width: 64px;
    height: 64px;
    position: fixed;
    right: 15px;
    bottom: 300px;
    background-color: #20D3E2;
    border-radius: 18px !important;
}
.btn_chat {
    width: 64px;
    height: 64px;
    position: fixed;
    right: 16px;
    bottom: 16px;
    background-color: transparent;
    border: none;
}
@media (max-width: 767px) {
    .page-sidebar-wrapper {
        margin-top: 100px;
    }
    .mob-navbar {
        z-index: 100;
    }
}

.btn-pagination {
    border: .5px #dcdddd solid;
    background-color: transparent;
    color: #337ab7;
}

.custom-list {
    list-style: none;
}
.custom-list li::before {
    content: "\2022";
    color: #32c5d2;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.custom-question-modal {
    border-radius: 36px !important;
}
.custom-close {
    margin-top: -20px;
}
