@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  :root {
    /* NOTE: Colors for cabinet.fm widget. Remove after ditching cabinet.fm */
    --cb-primary-bg: theme('colors.brand-green');
    --cb-primary-bg-hover: theme('colors.brand-green-hover');
  }

  /**
   * NOTE: Makes borders work without preflight
   * See: https://github.com/tailwindlabs/tailwindcss/discussions/8984
   */
  :where(
    [class*="tw-border"],
    [class*="tw-border"]::before,
    [class*="tw-border"]::after,
    [class*="tw-divide"] > *
  ) {
    border: solid 0 theme("borderColor.DEFAULT");
  }
}
