.footer {
  font-size: 13px;
  padding: 15px;
}

.footer.center {
  display: flex;
  justify-content: center;
}

.footer-inner {
  display: flex !important;
  flex-direction: column;
  gap: 6px;
}

.first-row {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.second-row {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

@media (min-width: 640px) {
  .footer-inner {
    gap: 4px;
  }

  .first-row {
    flex-direction: row;
    gap: 10px;
  }

  .second-row {
    gap: 8px;
  }
}
